<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogElement"
        persistent
        max-width="1000px"
        :retain-focus="false"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_element === 'add'"
                >Thêm Element</span
              >
              <span class="headline" v-else>Sửa Element</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogElement = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-switch
                    dense
                    v-model="is_pinned"
                    :label="`Pin ${is_pinned.toString()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <ElementChoose></ElementChoose>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogElement = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              @click="btnAddElement"
              outlined
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
export default {
  name: "ElementModal",
  components: {
    ElementChoose: () => import("./ElementChoose"),
  },
  mixins: [
    constantTestBuilder,
  ],
  data() {
    return {
      is_pinned: true,
    };
  },
  computed: {
    ...mapState({
      type_form_element: (state) =>
        state.contentTestBuilderStore.type_form_element,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    dialogElement: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogElement;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogElement", value);
      },
    },
    element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_post_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageFile",
          value
        );
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setVideoElementData",
          value
        );
      },
    },
    album_element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.album_element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setAlbumElementId", value);
      },
    },
    album_element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.album_element_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setAlbumElementData",
          value
        );
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPdfData", value);
      },
    },
    element_youtube_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_youtube_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementYoutube", value);
      },
    },
  },
  watch: {
    dialogElement: function (val) {
      if (val) {
        this.setDataInput();
        if (!this.canOpenDialogElement()) {
          this.$nextTick(() => {
            this.dialogElement = false;
          })
        }
      } else {
        this.element_youtube_data = null;
      }
    },
    type_element(val, oldVal) {
      if (val) {
        if (!this.canChangeTypeElement(val)) {
          this.$nextTick(() => {
            this.type_element = oldVal;
          })
        }
      }
    }
  },
  created() {},
  methods: {
    setDataInput() {
      if (this.type_form_element == "add") {
        this.is_pinned = true;
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.element_id = null;
        this.album_element_id = null;
        this.album_element_data = [];
        this.element_pdf_file = null;
        this.element_pdf_data = null;
        this.element_youtube_data = null;
      } else {
        let data = this.skills[this.indexSkill].sections[this.indexSection];
        this.type_element = data.element_type;
        this.element_post_data =
          data.element_type === "Post" ? data.element_data : null;
        this.element_image_data =
          data.element_type === "Image" ? data.element_data : null;
        this.element_audio_data =
          data.element_type === "Audio" ? data.element_data : null;
        this.element_pdf_data =
          data.element_type === "Pdf" ? data.element_data : null;
        this.video_element_data =
          data.element_type === "Video" ? data.element_data : null;
        this.element_youtube_data =
          data.element_type === "Embed_yt" ? data.element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_pdf_file = null;
        this.is_pinned = data.is_element_pinned == 1 ? true : false;
        this.element_id = this.checkElementId(
          this.type_element,
          data.element_id
        );
        this.video_element_id =
          this.type_element === "Video" ? data.element_id : null;
        this.album_element_id =
          data.element_type === "Album" ? data.element_id : null;
        this.album_element_data =
          data.element_type === "Album" ? data.element_data : [];
      }
    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type == "Video" || type == "Album") {
        check_element_id = null;
      }
      return check_element_id;
    },
    validateFormElement() {
      let flat = true;
      if (this.type_element == null) {
        this.$toasted.error("Hãy chọn loại element !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.type_element === "Video") {
        if (this.video_element_id == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Image") {
        if (this.element_image_data == null) {
          this.$toasted.error("Bạn chưa chọn tập tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Audio") {
        if (this.element_audio_data == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Pdf") {
        if (this.element_pdf_data == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Post") {
        if (this.element_post_data == null) {
          this.$toasted.error("Bạn chưa nhập dữ liệu !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Album") {
        if (this.album_element_data.length <= 0) {
          this.$toasted.error("Bạn chưa chọn tập tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Embed_yt") {
        if (
          this.element_youtube_data == null ||
          this.element_youtube_data === ""
        ) {
          this.$toasted.error("Bạn chưa nhập link youtube !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    async btnAddElement() {
      let validate = this.validateFormElement();
      if (!validate) {
        return;
      }
      this.is_call_api = true;
      let self = this;
      let valueElement = this.getValueElement(this.type_element);
      if (this.type_element === "Video") {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", self.section_id);
        formData.append("element_form_display", self.element_form_display);
        formData.append("element_id", self.video_element_id);
        formData.append("is_element_pinned", self.is_pinned === true ? 1 : 0);
        await ApiService.post(
          "prep-app/test/draft/section/upload-element",
          formData
        ).then(function (response) {
          if (response) {
            self.skills[self.indexSkill].sections[self.indexSection].element_type = self.type_element;
            self.skills[self.indexSkill].sections[self.indexSection].element_data = self.video_element_data;
            self.skills[self.indexSkill].sections[self.indexSection].is_element_pinned = self.is_pinned;
            self.skills[self.indexSkill].sections[self.indexSection].element_id = self.video_element_id;
            self.$set(self.skills);
            self.type_element = null;
            self.element_data = null;
            self.dialogElement = false;
            self.is_call_api = false;
          }
        });
      } else if (this.type_element === "Album") {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", self.section_id);
        formData.append("element_form_display", self.element_form_display);
        formData.append("element_id", self.album_element_id);
        formData.append("is_element_pinned", self.is_pinned === true ? 1 : 0);
        await ApiService.post(
          "prep-app/test/draft/section/upload-element",
          formData
        ).then(function (response) {
          if (response) {
            self.skills[self.indexSkill].sections[self.indexSection].element_type = self.type_element;
            self.skills[self.indexSkill].sections[self.indexSection].element_data = self.album_element_data;
            self.skills[self.indexSkill].sections[self.indexSection].is_element_pinned = self.is_pinned;
            self.skills[self.indexSkill].sections[self.indexSection].element_id = self.album_element_id;
            self.$set(self.skills);
            self.type_element = null;
            self.element_data = null;
            self.dialogElement = false;
            self.is_call_api = false;
          }
        });
      } else {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", this.section_id);
        formData.append("element_id", this.element_id);
        formData.append("element_form_display", this.element_form_display);
        if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
          formData.append("editor", this.checkElementData(this.type_element));
        }
        formData.append("is_element_pinned", self.is_pinned === true ? 1 : 0);
        if (this.checkElementFile(this.type_element) != null)
          formData.append("file", this.checkElementFile(this.type_element));
        this.is_call_api = true;
        await ApiService.post(
          "prep-app/test/draft/section/upload-element",
          formData
        )
          .then(function (response) {
            console.log(response)
            if (response) {
              self.skills[self.indexSkill].sections[self.indexSection].element_type = self.type_element;
              self.skills[self.indexSkill].sections[self.indexSection].element_data = self.checkElementData(self.type_element);
              self.skills[self.indexSkill].sections[self.indexSection].is_element_pinned = self.is_pinned;
              self.skills[self.indexSkill].sections[self.indexSection].element_id = response.data.element_code;
              self.$set(self.skills);
              self.type_element = null;
              self.element_data = null;
              self.dialogElement = false;
              self.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              self.is_call_api = false;
              self.$toasted.error(
                "Upload not successfully. Please try again or contact admin !!",
                {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                }
              );
              console.log(error);
            }
          });
      }
    },
    checkElementData(type) {
      if (type === "Audio") {
        return this.element_audio_data;
      }
      if (type === "Image") {
        return this.element_image_data;
      }
      if (type === "Post") {
        return this.element_post_data;
      }
      if (type === "Pdf") {
        return this.element_pdf_data;
      }
      if (type === "Embed_yt") {
        return this.element_youtube_data;
      }
      return null;
    },
    checkElementFile(type) {
      if (type === "Image") {
        return this.element_image_file;
      }
      if (type === "Audio") {
        return this.element_audio_file;
      }
      if (type === "Pdf") {
        return this.element_pdf_file;
      }
      return null;
    },
    getValueElement(type) {
      let valueElement;
      if (type === "Audio") valueElement = 7;
      else if (type === "Image") valueElement = 2;
      else if (type === "Video") valueElement = 5;
      // else if( type === 'Embed_yt') valueElement = 4;
      else if (type === "Post") valueElement = 6;
      else if (type === "Pdf") valueElement = 8;
      else if (type === "Album") valueElement = 9;
      else if (type === "Embed_yt") valueElement = 10;
      return valueElement;
    },
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    canChangeTypeElement(newType) {
      let flat = true;
      let section = this.skills[this.indexSkill].sections[this.indexSection];

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          if (section.partConfig) {
            let partConfig = section.partConfig;
            if (
                partConfig.module === this.section_config_module_constant.PREP_TALK_MODULE &&
                partConfig.product_category_id === this.product_category_constant.PREPTALK_ENGLISH &&
                partConfig.offset === this.section_offset_constant.OFFSET_1
            ) {
              if (!(newType === "Video")) {
                this.messError("Chỉ được chọn Video cho section này");
                flat = false;
              }
            }
          }
        }

      }
      return flat;
    },
    canOpenDialogElement() {
      let flat = true;
      let section = this.skills[this.indexSkill].sections[this.indexSection];

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          if (section.partConfig) {
            let partConfig = section.partConfig;
            if (
                partConfig.module === this.section_config_module_constant.PREP_TALK_MODULE &&
                partConfig.product_category_id === this.product_category_constant.PREPTALK_ENGLISH
            ) {
              if (
                  [
                      this.section_offset_constant.OFFSET_2,
                      this.section_offset_constant.OFFSET_3,
                  ].includes(partConfig.offset))
              {
                this.messError("Không thể thêm element cho section này");
                flat = false;
              }
            }
          }
        }

      }
      return flat;
    },
  },
};
</script>

<style></style>
